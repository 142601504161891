import { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled, { css, keyframes } from 'styled-components';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { CardsDataPackage } from '../../../../context/contexts/data/requests/cards';
import { useGlobalContext } from '../../../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../../../context/contexts/text/TextContext';
import Theme from '../../../../style/theme';
import { ImageDiv } from '../../../../utilities/types';
import { CardState, useGameContext } from '../Game';

export const cardAspectRatio = '1/2.1153';
export const baseFontSize = 16;

export const Card = (props: CardProps) => {

	const {SSV} = useGlobalContext();
	const {getFileURL} = useDataContext();
	const { t } = useTextContext();
	const {cardsStates, activeRound, rounds} = useGameContext();

	const [spot, setSpot] = useState<HTMLElement>();

	const ref = useRef<HTMLDivElement>(null);

	const [cardState, setCardState] = useState<CardState>();
	const [zIndex, setZIndex] = useState<number>(0);
	
	const [initialized, setInitialized] = useState<number>(0);

	// sets the cardState of the card

	useEffect(() => {
		if(!cardsStates || !props.data.data.id || !cardsStates[props.data.data.id]) return;
		setCardState(cardsStates[props.data.data.id]);
	},[cardsStates]);

	// sets the spot of the card

	useEffect(() => {
		if(!cardState) return;
		setInitialized(a => a+1);
		updateFontSize();
		setTimeout(() => {
			const s = document.getElementById(cardState.spotId);
			if(s){
				setSpot(s);
			}
		}, 10);
	},[cardState]);

	useEffect(() => {
		if(activeRound === -1 || activeRound === undefined || !rounds || rounds.length < 1 || !cardState) return;
		const activeCardId = rounds[activeRound].activeCardId;
		setZIndex(activeCardId === props.data.data.id ? 3 : cardState.positionInDeck ? -cardState.positionInDeck : 0);
	},[activeRound, rounds, cardState]);


	//#region font size

	const [fontSize, setFontSize] = useState<number | undefined>(baseFontSize);

	const updateFontSize = () => {
		if( !baseFontSize || !spot) return;
		const diff = getResolutionDifference([spot.clientWidth, spot.clientHeight]);
		if(diff)
			setFontSize(baseFontSize * diff[0]);
	};

	const getResolutionDifference = (currentResolution: [number, number]) => {
		const widthDiff = currentResolution[0] / 550;
		const heightDiff = currentResolution[1] / 260;
		return [widthDiff, heightDiff];
	};

	useEffect(() => {
		if(spot){
			updateFontSize();
		}
	},[spot]);

	//#endregion

	const image = useMemo(() => {
		return getFileURL && getFileURL(props.data.data.image ? props.data.data.image : '');
	},[props.data]);

	const cardbackIcon = useMemo(() => {
		return getFileURL && getFileURL('dc576297-beed-49be-a5d5-257ad72fd8ac');
	},[getFileURL]);

	const cardbackText = useMemo(() => {
		return getFileURL && getFileURL('7a1e967c-422d-48cb-a41f-3450d4fa30ae');
	},[getFileURL]);

	return(
		<>
			{spot && cardState && props.data.translation &&
				<Container 
					spot={spot} 
					spotId={cardState.spotId}
					positionInDeck={cardState.positionInDeck}
					ref={ref} 
					faceUp={cardState.faceup} 
					zIndex={zIndex}
					initialized={initialized}
					fontSize={fontSize}
				>	
					{ SSV &&
					<Cardback faceUp={cardState.faceup}>
						<CardBackTitle url={cardbackText}/>
						<CardBackIcon url={cardbackIcon}/>
					</Cardback>
					}

					<CardFront faceUp={cardState.faceup} type={props.data.data.card_type}>
						<CardImage url={image} type={props.data.data.card_type}/>
						<CardTitle>{props.data.translation.title}</CardTitle>
						<CardDescription><ReactMarkdown>{props.data.translation.body ? props.data.translation.body : 'no description data found'}</ReactMarkdown></CardDescription>
						<CardType url={props.data.data.card_type === 'action' ?  props.typeImages['action'] : props.typeImages['opinion']} type={props.data.data.card_type}/>
					</CardFront>

				</Container>
			}
		</>
	);
};

// styled components

const changeToFaceUp = keyframes`
	0%{
		transform: rotateY(180deg) scale(1);
	}
	50% {
		transform: rotateY(90deg) scale(1.1);
	}
	100%{
		transform: rotateY(0deg) scale(1);
	}
`;

const changeToFaceDown = keyframes`
	0%{
		transform: rotateY(0deg) scale(1);
	}
	50% {
		transform: rotateY(90deg) scale(1.1);
	}
	100%{
		transform: rotateY(180deg) scale(1);
	}

`;

const Container = styled.div<{spot: HTMLElement, faceUp: boolean, zIndex: number, initialized: number, fontSize: number | undefined, spotId: string | undefined, positionInDeck: number | undefined}>`
	position: absolute;

	${p => {
		const bcr = p.spot.getBoundingClientRect();
		return `
			left: ${bcr.left}px;
			right: ${bcr.right}px;
			top: ${bcr.top}px;
			bottom: ${bcr.bottom}px;
		`;
	}}

	transform: rotateY(180deg);

	${ p => p.initialized > 1 && css`
		animation: ${p.faceUp ? changeToFaceUp : changeToFaceDown} forwards 1s;
	`}

	aspect-ratio: ${cardAspectRatio};
	height: ${p => p.spot.clientHeight}px;

	transition: all 1s;
	transform-style: preserve-3d;
	z-index: ${p => p.zIndex};

	font-family: 'NunitoSans';
	font-size: ${p => p.fontSize}px;

	backface-visibility: hidden;


`;

interface CardProps extends HTMLAttributes<HTMLButtonElement> {
	data: CardsDataPackage;
	typeImages: { action: string | undefined, opinion: string | undefined}
}

const Cardback = styled.div<{faceUp: boolean}>`

	position: absolute;
	inset:0;
	background-color: ${Theme.colors.secondary};
	backface-visibility: hidden;
	transform: rotateY(180deg);
	border-radius: 20px;
	padding: 7%;
	
	display: flex;
	flex-direction: column;

`;

const CardBackTitle = styled.div<ImageDiv>`

	height: 80%;	

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	filter: invert(100%);

`;


const CardBackIcon = styled.div<ImageDiv>`
	height: 15%;
	margin-top: auto;
	/* background-color: green; */
	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	filter: invert(100%);

`; 

const CardFront = styled.div<{faceUp: boolean, type: string | undefined}>`

	position: absolute;
	inset:0;
	background-color: ${p => p.type === 'action' ? Theme.colors.primary : Theme.colors.secondaryLighter};
	color: ${p => p.type === 'action' ? Theme.colors.neutralDarkest : Theme.colors.neutralLightest};

	border-radius: 20px;
	backface-visibility: hidden;
	padding: 7%;

	overflow: hidden;

	display: flex;
	flex-direction: column;
	/* filter: drop-shadow(0 0 0 0.1 black); */


`;

const CardImage = styled.div<ImageDiv & {type: string | undefined}>`
	height: 35%;
	/* background-color: hotpink; */

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	filter: invert(${p => p.type === 'opinion' ? '100%' : '0%'});
`;

const CardTitle =  styled.div`

	position: relative;

	height: 15%;
	/* background-color: purple; */
	font-weight: bold;
	font-size: 2em;

	display: flex;
	justify-content: center;

`;

const CardDescription = styled.div`

	height: 35%;
	/* background-color: green; */

	display: flex;
	flex-direction: column;
	align-items: start;

	& > p {
		font-size: 1.6em;
		font-weight: bold;
		text-align: center;
		width: 100%;
	}

`;

const CardType = styled.div<ImageDiv & {type: string | undefined}>`
	height: 15%;
	filter: invert(${p => p.type === 'opinion' ? '100%' : '0%'});

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;
