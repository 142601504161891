import { ID } from '@directus/sdk';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled, { css, keyframes } from 'styled-components';
import { useDataContext } from '../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { fadeIn, fadeOut } from '../../style/animations';
import Theme from '../../style/theme';
import { Button } from '../components/buttons/Button';
import { CloseButton } from '../components/buttons/CloseButton';
import { InformationButton } from '../components/buttons/InformationButton';
import { RoundButton } from '../components/buttons/RoundButton';

export const Rules = () => {

	const {activeRule, gameState, setActiveRule, unlockedRules, setUnlockedRules, unlockRule} = useGlobalContext();
	const {data} = useDataContext();

	const [selected, setSelected] = useState<ID>(0);
	const [text, setText] = useState<string>();

	const tabs = useRef<HTMLDivElement>(null);

	// unlock rules based on gameState
	useEffect(() => {
		if(!unlockRule) return;
		switch(gameState){
		case 3:
			setTimeout(() => {
				unlockRule(3);
			}, 2000);
			break;
		case 4:
			setTimeout(() => {unlockRule && unlockRule(2);}, 2000);
			break;
		}
	},[gameState]);

	// watch changes of activeRule
	useEffect(() => {
		if(!setUnlockedRules || !unlockedRules || activeRule === undefined || !data) return;
		if(activeRule != -1) 
			setSelected(activeRule);

		const el = document.getElementById(`rule-tab-${selected}`);
		if(!el || !tabs.current) return;
		const y = el.offsetTop;
		tabs.current.scroll({
			top: y,
			behavior: 'smooth'
		});

	},[activeRule]);

	useEffect(() => {
		if(!data) return;

		const el = document.getElementById(`rule-tab-${selected}`);
		if(!el || !tabs.current) return;
		const y = el.offsetTop;
		tabs.current.scroll({
			top: y,
			behavior: 'smooth'
		});

		const newText = data.rules.find(el => el.data.id === activeRule)?.translation.text;
		if(newText)
			setText(newText);
	},[selected]);

	useEffect(( ) => {

		if(!data || !data.rules || !setUnlockedRules) return; 

		const newUnlocks : ID[] = [];

		data.rules.map((value, index) => {
			if(!value.data.start_locked && value.data.id){
				newUnlocks.push(value.data.id);
			}
		});
		setUnlockedRules([...newUnlocks]);
	},[data]);

	const [active, setActive] = useState(false);
	useEffect(() => {
		if(activeRule !== undefined){
			setActive(true);
		}
	},[activeRule]);

	return(
		<>
			{ active &&
			<>
				<Container fadeOut={activeRule === undefined} >
					<Content slideOut={activeRule === undefined}>
						<Tabs ref={tabs}>
							{ data && data.rules && unlockedRules && data.rules.map((rule) =>
								<Tab 
									disabled={unlockedRules.includes(rule.data.id !== undefined ? rule.data.id : '') ? false : true}
									key={`rule-tab-${rule.data.id}`} 
									id={`rule-tab-${rule.data.id}`}
									selected={selected === rule.data.id}
									onClick={() => {setActiveRule && setActiveRule(rule.data.id);}}
								>{rule.translation.title}</Tab>
							)}
						</Tabs>
						<TabContent>
							<ScrollingContainer>
								<ReactMarkdown>{text ? text : 'no rule found'}</ReactMarkdown>
							</ScrollingContainer>
						</TabContent>
					</Content>
				</Container>
				{ activeRule !== undefined &&
					<>
						<Blur/>
						<InformationButton style={{zIndex: 3}}/>
					</>
				}
			</>
			}
		</>

	);
};

const slideIn = keyframes`
	0%{
		transform: translateY(100vh);
	}
	100%{
		transform: translateY(0);
	}
`;

const slideOut = keyframes`
	0%{
		transform: translateY(0);
	}
	100%{
		transform: translateY(100vh);
	}
`;

const Container = styled.div<{fadeOut: boolean}>`
	position: absolute;
	height:50%; width: 70%;

	left:0;bottom:0;

	animation: ${p => p.fadeOut ? fadeOut : fadeIn} 1s forwards;
	animation-delay: ${p => p.fadeOut ? '.15s' : ''};

	z-index:4;

	padding: 20px;

	margin-bottom: 70px;
	margin-left: 10px;

`;

const Content = styled.div<{slideOut: boolean}>`
	animation: ${p => p.slideOut ? slideOut : slideIn} forwards ${p => p.slideOut ? '.3s ease-in' : '.5s ease-out'};
	height: 100%; width: 100%;
	/* background-color: pink; */
	display: flex;
	gap: 20px;
`;

const CloseColumn = styled.div`
`;

const StyledCloseButton = styled(CloseButton)`
	position: absolute;
	right:0;top:0;
	margin: 10px;

	height: 30px;
	width: 30px;
`;

const Tabs = styled.div`
	flex-shrink: 0;
	width: 25%; // lacks responsive
	display: flex;
	flex-direction: column;
	gap: 20px;
	overflow-y:auto;
	/* overflow-x:visible; */
	/* overflow-x:hidden; */

	direction: rtl;

	box-sizing: border-box;

	padding-left: 10px;


	& > * {
		direction: ltr;
	}
`;

const Tab = styled(Button)<{selected: boolean}>`
	padding-inline: 0.5em;
	padding-block: 0.25em;
	/* margin-right: -100px; */
	width: 100%;	

	${p => p.selected && css`

		/* margin-right: -50px; */
		color: ${Theme.colors.neutralDarkest};
		text-decoration: underline;

	`}

	/* border: solid 2px ${Theme.colors.neutralDarker}; */
	/* background-color: ${p => p.selected ? Theme.colors.primary : Theme.colors.neutralLightest}; */
`;

const Blur = styled.div`
	position: absolute;
	inset:0;
	background: rgba(0,0,0,0.5);
	backdrop-filter: blur(5px);
	z-index: 3;
`;

const TabContent = styled.div`
	flex-grow:1;
	padding-inline: 50px;
	padding-block: 1rem;

	background-color: ${Theme.colors.secondaryLightest};
	overflow-y: auto;

	color: ${Theme.colors.neutralDarkest};
`;

const ScrollingContainer = styled.div`
`;