import { useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useDataContext } from '../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { language, useTextContext } from '../../context/contexts/text/TextContext';
import Theme from '../../style/theme';
import { ImageDiv } from '../../utilities/types';
import { Button } from '../components/buttons/Button';
import { Container } from './components/Container';

export const Login = () => {

	const {gameState, setGameState} = useGlobalContext();
	const {t} = useTextContext();
	const {data, getFileURL} = useDataContext();

	const [input, setInput] = useState<string>('');
	const [error, setError] = useState<boolean>(false);

	const checkCode = (e) => {
		e.preventDefault();
		if(!data || !data.loginCode ) return;
		if(data.loginCode === input){
			setGameState && setGameState(2);
			setError(false);
		}
		else{
			setError(true);
		}
	};

	const languageImages = useMemo<(string | undefined)[]>(() => {
		return [
			getFileURL && getFileURL('20df5ae4-2c78-4068-a5b7-ecb40aaaf509'),
			getFileURL && getFileURL('58ca000c-4331-4bc6-97fc-1c50f8a64747'),
		];
	},[getFileURL]);

	const otherImages = useMemo<(string | undefined)[]>(() => {
		return [
			getFileURL && getFileURL('3e48e532-2060-43f3-bacb-f90b1fc42a31'),
			getFileURL && getFileURL('58ca000c-4331-4bc6-97fc-1c50f8a64747'),
			getFileURL && getFileURL('5de08377-7087-4a59-b81a-7c50eaedea6d'),
		];
	},[getFileURL]);

	return (
		<Container gameState={1}>
			
			<Bar>
				<Logo url={otherImages[0]}><Arrow url={otherImages[2]}/><Cover url={otherImages[0]}/></Logo>
				<GameName><ReactMarkdown>{t('login.gamename', true)}</ReactMarkdown></GameName>
			</Bar>
			<Content>
				<InputGroup>
					{/* language select row */}
					<div>
						<LanguageContainer>
							<LanguageButton url={languageImages[0]} active={language === 'nl-NL'} onClick={() => {localStorage.setItem('locale', 'nl-NL'); location.reload();}}/>
							<LanguageButton url={languageImages[1]} active={language === 'en-US'} onClick={() => {localStorage.setItem('locale', 'en-US'); location.reload();}}/>
						</LanguageContainer>
					</div>
					<div style={{width: '100%'}}>
						{error && <div style={{color: 'red', paddingBlock: '5px'}}>{t('login.wrongcode')}</div>}
						<form style={{display: 'flex', gap: '2rem'}} onSubmit={(e) => {checkCode(e);}}>
							<input style={{width: '100%', paddingInline: '1rem', border: 'none'}} placeholder={t('login.input.placeholder')} onChange={(e) => setInput(e.target.value)}/>
							<Button type={'submit'}>{t('login.input.submit')}</Button>
						</form>
					</div>
				</InputGroup>
				{/* <button onClick={() => {localStorage.setItem('locale', language === 'en-US' ? 'nl-NL' : 'en-US'); location.reload();}}>test</button> */}
			</Content>
		</Container>
	);
};

const Content = styled.div`

	position: relative;

	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;

`;

const InputGroup = styled.div`
	margin-left: auto;
	margin-top: auto;
	width: 50%;
	display: flex;
	flex-direction: column;
`;

const Logo = styled.div<ImageDiv>`

	position: absolute;
	top:0;right:10%;
	left:60%;

	transform: translateY(-100%);

	aspect-ratio: 878/215;

	align-self: end;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;

const Arrow = styled.div<ImageDiv>`
	left:0;bottom:0;
	transform: translate(0, 246%);

	width: 18.5%;
	/* height: 100%; */
	aspect-ratio: 215/115;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: bottom;
	background-repeat: no-repeat;
`;

const Cover = styled.div<ImageDiv>`
	position: absolute;
	inset:0;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;


const LanguageContainer = styled.div`
	background-color: ${Theme.colors.neutral};
	height: 60px;
	width: 200px;
	/* width: 600px; */

	display: flex;

	margin-bottom: 20px;
`;

const Bar = styled.div`
	position: absolute;
	left:0;right:0;
	top:35%;
	bottom: 45%;
	background-color: ${Theme.colors.secondary};

	& > p {
		position: absolute;
		right:0;
	}
`;

const GameName = styled.div`
	position: absolute;
	right:10%;
	top:0;
	bottom:0;

	display: flex;
	flex-direction: column;
	justify-content: center;

	text-align: right;

	& > p {
		/* font-weight: 300; */
		font-size: 2em;
		/* line-height: 100%; */
		margin: 0;
	}

	& > p:nth-of-type(1) {
		font-weight: 100;
	}

	& > p:nth-of-type(2) {
		font-weight: 300;
	}
`;

const LanguageButton = styled.button<ImageDiv & {active: boolean}>`
	flex-grow: 1;
	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border: none;
	background-color: ${p => p.active ? `${Theme.colors.neutralLighter}` : 'transparent'};;
	outline:  ${p => p.active ? `${Theme.colors.secondary} solid 2px` : ''};

	&:hover{
		cursor: pointer;
	}
`;