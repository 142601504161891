import Plausible from 'plausible-tracker';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const { trackPageview } = Plausible({
	domain: 'di.tqwi.nl',
	hashMode: true,
	trackLocalhost: true
});
trackPageview();  
  

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
