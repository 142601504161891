import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../../context/contexts/text/TextContext';
import Theme from '../../../style/theme';
import AARContainer from '../../components/AARContainer';
import { Button } from '../../components/buttons/Button';
import { cardAspectRatio } from './Cards/Card';
import { useGameContext } from './Game';
import * as htmlToImage from 'html-to-image';
import * as download from 'downloadjs';

export const End = () => {

	const {unlockRule} = useGlobalContext();
	const {cardsStates, activeStep, setCardState} = useGameContext();
	const { setGameState, SSV, setSSV } = useGlobalContext();
	const { t } = useTextContext();


	useEffect(() => {

		if(!cardsStates || !setCardState) return;

		let i = 0;

		if(activeStep === 'end'){

			
			Object.values(cardsStates).map((value, index) => {
				if(value.spotId.includes('cardspot-top3')){
					setCardState(value.cardId, `cardspot-end-${i}`);
					i++;
				}
			});

			setTimeout(() => {
				unlockRule && unlockRule(8);
			},2000);
			
		}

	},[activeStep]);

	const screenshot = () => {
		setSSV && setSSV(false);
		htmlToImage.toJpeg(document.body, {quality: 1})
			.then(function (dataUrl) {
				download(dataUrl, 'd&icardgame-onzetop3.png');
				setSSV && setSSV(true);
			});
	};

	return (
		<Container> 
			<Top3Container>
				{/* add scaling */}
				<Title>{t('game-end-title')}</Title> 
				<CardSpots>
					{ [...Array(3)].map((value, index) => 
						<CardSpot 
							key={`cardspot-end-${index}`}
							id={`cardspot-end-${index}`}
						/>
					) }
				</CardSpots>
			</Top3Container>
			<div style={{width: '22.5%', display: 'flex', flexDirection: 'column-reverse', marginLeft: '2.5%', gap: '2%', visibility: SSV ? 'visible' : 'hidden'}}>
				<Button onClick={() => setGameState && setGameState(3)}>{t('game-end-playagain')}</Button>
				<Button onClick={() => {screenshot();}}>{t('game-end-screenshot')}</Button>
			</div>
		</Container>

	);

};

const Container = styled.div`
	display: flex;
	height: 100%;
`;

const Top3Container = styled.div`
	margin-left: 10%;	
	width: 70%;
	display: flex;
	flex-direction:column;

	background-color: ${Theme.colors.secondaryLightest};
	border-radius: 1em;
	padding: 4%;
`;

const CardSpots = styled.div`
	flex-grow:1;
	display: flex;
	justify-content: space-between;
`;

const CardSpot = styled.div`
	aspect-ratio: ${cardAspectRatio};
	background-color: ${Theme.colors.neutral};
	border-radius: 1em;
`;

const Title = styled.h1`
	font-weight: 300;
	font-size: 3em;
	color: ${Theme.colors.secondary};
	margin-bottom: 1em;
	margin-top: 0;
`;