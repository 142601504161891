import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { fadeIn, fadeOut } from '../../../style/animations';
import { shuffle } from '../../../utilities';
import AARContainer from '../../components/AARContainer';
import { InformationButton } from '../../components/buttons/InformationButton';
import { Container } from '../components/Container';
import { Announcer } from './Announcer';
import { Cards } from './Cards/Cards';
import { Center } from './Center';
import { End } from './End';
import { Focus } from './Focus';
import { PlayersList } from './PlayersList';
import { Right } from './Right';

const GameContext = createContext<Partial<GameContextProps>>({});

export const Game = () => {

	const {gameState, players, setActiveRule, unlockRule} = useGlobalContext();
	const {getFileURL, data} = useDataContext();

	const [active, setActive] = useState<boolean>(false); useEffect(() => {if(gameState === 4) setActive(true); else(setActive(false));},[gameState]);

	const [cardsStates, setCardsStates] = useState<cardsStates>();
	const [deck, setDeck] = useState<number[]>([]);

	// #region round logic

	const [activePlayerId, setActivePlayerId] = useState<string>();
	const [activeRound, setActiveRound] = useState<number>(-1);
	const [activeStep, setActiveStep] = useState<string>('prestart');

	const [rounds, setRounds] = useState<Round[]>([]);

	const [proceedFromVoting, setProceedFromVoting] = useState<boolean>(false);

	//#region game setup

	useEffect(() => {
		if(!deck || !cardsStates) return;
		if(gameState === 4)
			setupGame();
		else if(isGameSetup){
			setIsGameSetup(false);
			setActiveStep('prestart');
			setDeck([]);
			setCardsStates(undefined);
			setActiveRound(-1);
		}
	},[deck, cardsStates, gameState]); // make sure to add data which we need to setup the game.

	const [isGameSetup, setIsGameSetup] = useState<boolean>(false);
	const setupGame = () => {
		if(isGameSetup || !deck || !cardsStates || !players) return;
		let playerCounter = 0;
		const playerIds = Object.keys(players);
		const opponents : string[] = [];
		const newRounds : Round[] = [];

		let opponentCounter = 0;
		for(let i = 0; 6 > i; i++){
			if(!playerIds[opponentCounter]) opponentCounter = 0;
			opponents.push(playerIds[opponentCounter]);
			opponentCounter++;
		}
		shuffle(opponents);

		let opponentUsedCounter = 0;
		for(let i = 0; deck.length > i; i++){

			const newRound: Round = {
				activePlayerId: playerIds[playerCounter],
				activeCardId: deck[i],
			};

			if(data?.cards[deck[i]].data.card_type === 'opinion'){
				if(opponentUsedCounter > opponents.length){
					opponentUsedCounter = 0;
				}
				else{
					if(opponents[opponentUsedCounter] !== newRound.activePlayerId)
						newRound.opponentId = opponents[opponentUsedCounter];
					else{
						newRound.opponentId = opponents[(opponentUsedCounter + 1) < opponents.length ? opponentUsedCounter + 1 : opponentUsedCounter - 1];
					}
					opponentUsedCounter += 1;
				}
			}

			newRounds.push(newRound);
			
			if(!playerIds[playerCounter + 1]){
				playerCounter = 0;
			}
			else{
				playerCounter++;
			}

		}
		setActiveRound(-1);
		setRounds(newRounds);
		setIsGameSetup(true);
	};

	//#endregion

	//#region game flow handlers
	
	useEffect(() => {
		switch(activeStep){
		case 'draw':
			setCardState(undefined,undefined, true);
			// if(activeRound < 3){
			// 	setTimeout(() => setActiveStep('replacetop3'), 500);
			// }
			// else{
			// 	setTimeout(() => setActiveStep('discardactivecard'), 500);
			// }
			setTimeout(() => setActiveStep('processcard'), 1500);
			break;
		case 'discardactivecard':
			setCardState(undefined, 'cardspot-discard');
			setTimeout(() => proceedToNextRound(), 2000);
			break;
		}
	},[activeStep]);

	const proceedToNextRound = () => {
		if(activeRound === -1){
			setActiveRound(0);
			setActivePlayerId(rounds[0].activePlayerId);
			setActiveStep('nextturn');
		}
		else if(!rounds[activeRound + 1]){
			// show end
			setActiveStep('end');
		}
		else{
			setActivePlayerId(rounds[activeRound + 1].activePlayerId);
			setActiveRound(a => a + 1);
			setActiveStep('nextturn');
		}

		//if there are no cards left in the deck, show end state
		//else proceed to next round logic
		// 1. set next active player
		// 2. set activeRound tot activeRound ++
		// 3. initiate drawing card sequence.
	};

	//#endregion

	const setCardState : SetCardState = (id, spotId, faceup, positionInDeck?) => {
		setCardsStates( a => {
			const activeCardId = rounds[activeRound].activeCardId;
			if(!a || !a[id ? id : activeCardId]) return a;

			const currentState = a[id ? id : activeCardId];
			a[id ? id : activeCardId] = {
				spotId: spotId ? spotId : currentState.spotId,
				faceup: faceup ? faceup : currentState.faceup,
				positionInDeck: positionInDeck ? positionInDeck : currentState.positionInDeck,
				cardId: currentState.cardId,
			};

			return {...a};
		});
	};

	const playerImages : (string | undefined)[] = useMemo(() => {

		if(!getFileURL) return [];

		return [
			getFileURL('3ff82eb4-6ecd-4340-acc3-810bdd89f1d6'),
			getFileURL('9795b347-02ab-4eed-9b0e-2461844c40cc'),
			getFileURL('12cc104a-37c7-441f-b7da-6fcdeada9c8b'),
			getFileURL('d911c57a-bbf8-4130-bc6e-566071399438'),
			getFileURL('bb0a9789-79ef-4fe1-b49c-aa0d97fb5a23'),
			getFileURL('8dca1152-437e-48b3-8e44-759fa8dfcd71'),
			getFileURL('d98cb3ed-8e3a-498d-a89f-53ed32764b7e'),
		];

	},[getFileURL]);


	const passedValues = {

		cardsStates, 
		setCardsStates, 
		setCardState, 
		
		setDeck,

		activeStep,
		setActiveStep,

		activeRound,
		rounds,

		proceedToNextRound,

		setProceedFromVoting, 
		proceedFromVoting

	};

	return (
		<> {active && 
			<GameContext.Provider value={{...passedValues}}>
				<Container gameState={4} style={{paddingLeft: '0', display: 'flex'}} id={'game-screen'}>
					<PlayersList activePlayerId={activeStep === 'end' ? 'none' : activePlayerId} playerImages={playerImages}/>
					{/* Game */}
					<SubContainer>
						{/* Game */}
						<GameContent fadeOut={activeStep === 'end'}>
							<AARContainer fitParent aspectRatio='2/1' originalResolution={[1600, 800]} fontSize={16}> 
								<div style={{display: 'flex', height: '100%'}}>
									<Center/>
									{/* 1120 */}
									<Right/>
									{/* 480 */}
								</div>
							</AARContainer>
						</GameContent>
						{/* End */}
						<GameContent fadeIn={activeStep === 'end'} style={{opacity: activeStep === 'end' ? '' : 0, pointerEvents: activeStep === 'end' ? 'all' : 'none'}}>
							<AARContainer fitParent aspectRatio='2/1' originalResolution={[1600, 800]} fontSize={16}>
								<End/>
							</AARContainer>
						</GameContent>
					</SubContainer>
					{/* End */}
					<InformationButton style={{margin: '10px'}}/>
				</Container>
				<Cards>
					<Focus playerImages={playerImages}/>
				</Cards>
				<Announcer/>
			</GameContext.Provider>
		}
		</>
	);
};

export const useGameContext = () => useContext(GameContext);


// styled components

const SubContainer =  styled.div`
	position: relative;
	height: 100%;
	flex-grow: 1;
`;

const GameContent = styled.div<{fadeOut?: boolean, fadeIn?: boolean}>`
	position: absolute;
	inset:0;

	${p => p.fadeOut && css`
		animation: ${fadeOut} 1s forwards;
	`}

	${p => p.fadeIn && css`
		animation: ${fadeIn} 1s forwards;	
	`}
`;

// #region types

// context

type GameContextProps = {
	cardsStates: cardsStates
	setCardsStates: React.Dispatch<React.SetStateAction<cardsStates | undefined>>
	setCardState: SetCardState,

	setDeck: React.Dispatch<React.SetStateAction<number[]>>

	activeStep: string,
	setActiveStep: React.Dispatch<React.SetStateAction<string>>

	activeRound: number,
	rounds: Round[],

	proceedToNextRound: () => void,

	proceedFromVoting: boolean;
	setProceedFromVoting: React.Dispatch<React.SetStateAction<boolean>>
}

// rounds

type Round = {
	activePlayerId: string,
	activeCardId: number,
	opponentId?:string,
}

// cards

type SetCardState = (id?: number, spotId?: string, faceup?:boolean, position?:number) => void;

type cardsStates = {
	[key:string]:CardState
};

export type CardState = {
	spotId: string,
	faceup: boolean,
	positionInDeck?: number,
	cardId: number,
}

// #endregion