import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { RoundButton } from './RoundButton';

export const InformationButton = (props: InformationButtonProps) => {

	const {setActiveRule, activeRule} = useGlobalContext();

	return (
		<StyledRoundButton {...props} icon={activeRule !== undefined ? 'close' : 'information'} onClick={() => {setActiveRule && setActiveRule(a => a !== undefined ? undefined : props.defaultRule ? props.defaultRule : -1);}}/>
	);
};

const StyledRoundButton = styled(RoundButton)`

	height: 50px!important;
	width: 50px!important;

	position: absolute;
	left:0;
	bottom:0;
	padding: 0;
	margin: 10px;

`;

// types

interface InformationButtonProps extends HTMLAttributes<HTMLButtonElement> {
	defaultRule?: number
}